.modal-title {
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;
    font-family: Ubuntu, Arial, sans-serif;
    letter-spacing: 0!important;
    color: $primary;
    font-weight: 400;
    font-size: 1.5rem;
}

.modal-body-title1 {
    font-weight: 700;
    font-size: 30px;
    margin: 15px 0;
    color: $primary;
    line-height: 1.35;
    font-family: Ubuntu, Arial, sans-serif;
    letter-spacing: 0!important;
}

.modal-body-title2 {
    color: #505050;
    font-size: 26px;
    line-height: 36px;
    text-transform: none;
    font-weight: 700;
    font-family: Ubuntu, Arial, sans-serif;
    letter-spacing: 0!important;
    margin-top: 0;
    margin-bottom: 25px;
}